<template lang="pug">
structure-h1(:title="$t('invoices.title1')")
structure-card
  b {{ $t('invoices.box1_title') }}
  p {{ $t('invoices.box1_sub_title') }}
  p.warning(v-if="$store.getters['user/isValidAutoInvoice']") {{ $t('invoices.box1_sub_title2') }}
  p.error(v-else) {{ $t('invoices.box1_sub_title3') }}

structure-card.p-0.overflow-hidden
  table.w-full
    thead
      tr.text-center
        th ID
        th {{ $t('invoices.table_date') }}
        th {{ $t('invoices.table_description') }}
        th {{ $t('invoices.table_method_payment') }}
        th {{ $t('invoices.table_price') }}
        th {{ $t('globals.options') }}
    tbody.text-center
      tr(v-for="invoice in list_invoices", :key="invoice._id")
        td {{ invoice.invoice_num }}
        td {{ setDate(invoice.invoice_date) }}
        td {{ invoice.invoice_product_name }}
        td {{ invoice.invoice_payment_method }}
        td {{ invoice.invoice_price }} {{ invoice.invoice_price_currency }}
        td.flex.justify-center.button-group
          div(@click="downloadInvoice(invoice._id)") Download
</template>
<script>
import userRepository from "@/repositories/userRepository";

export default {
  name: "invoices",
  data: () => ({
    list_invoices: [],
  }),

  async created() {
    this.list_invoices = await userRepository.getMeInvoices();
  },

  methods: {
    setDate: (d) => {
      const date = new Date(d);
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    downloadInvoice(invoice_id) {
      userRepository.getMeInvoicePDF(invoice_id);
    },
  },
};
</script>
<style lang="scss" scoped>
.warning {
  @apply text-yellow-500;
}
.error {
  @apply text-red-500;
}
</style>